.none {
  display: none;
}
.file-upload--container {
  position: relative;
  margin: 10px 0px;
  width: 100%;
  height: 250px;
  background-color: #F4F5F7;
  border: 1px dashed #4daae7;
  border-radius: 5px;
}
.file-upload--description {
  position: absolute;  
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.file-upload--container label {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.file-upload--drop {
  border: 1px solid #000;
}
.dialog-actions > button {
  margin-bottom: 15px;
}
.file-upload--notice {
  align-items: center;
  flex-wrap: 'wrap',
}
.file-upload--notice > p {
  margin-top: 0;
  padding-left: 5px;
  margin-block-end: 0;
}
.file-upload-item {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  z-index: 1;

  background-color: #EEEEEE;
  transition: all 0.15s ease-in-out;
}
.file-upload-item:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: #f6f6f6;
}

.file-upload-item--details {
  margin: auto 10px;
  line-height: 30px;
}

.file-upload-item--details > p {
  margin: 0;
}
.file-upload-item--actions {
  z-index: 1000;
  height: fit-content;
  margin: auto 0 !important;
}

.toggle-container {
  width: 100%;
  margin-bottom: 15px;
}
.toggle-container > .toggle-button {
  width: 50%;
}
.small-icon {
  height: 16px;
  margin-right: 6px;
}
.small-icon.disabled {
  opacity: 25%;
  filter: invert(1);
}

.grid-columns {
  display: grid;
  flex-wrap: wrap;
  align-content: flex-start;
}

.grid-self-end {
  align-self: flex-end;
}

.grid-justify-self-end {
  justify-self: flex-end;
}