:root{
    --arrow-color: rgb(204, 204, 204);
}

.vertical {
    position: relative;
    width: 2px;
    height: 65px;
    top: 10px;
    background-color: var(--arrow-color);
}

.vertical::before {
    top: 0;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent var(--arrow-color) transparent;
}

.grot {
    position: absolute;
    width: 2px;
    height: 12px;
    background-color: var(--arrow-color);
}

.grot-left-bottom {
    top: 100%;
    transform: translate(-100%, -100%) translate(-1.5px, 2px) rotate(-45deg);
}

.grot-right-bottom {
    top: 100%;
    transform: translate(0, -100%) translate(3.5px, 2px) rotate(45deg);
}

.grot-left-top {
    transform: translate(-100%, 0) translate(-1.5px, -2px) rotate(45deg);
}

.grot-right-top {
    transform: translate(0, 0) translate(3.5px, -2px) rotate(-45deg);
}